import { z } from 'zod';
import { zfd } from 'zod-form-data';
import Payments from '~/stores/payments';
import api from '~/utils/api';
import { pendingClaimsToClaimLineItemCreate } from '~/utils/claim-line-items';
import { ClaimType } from '~/utils/constants';
import {
  createActionResultHook,
  crewAction,
  error,
  json,
  redirect,
} from '~/utils/routing';
import { ActionResult } from '~/utils/types';

export enum FormKey {
  paymentIntentSecret = 'paymentIntentSecret',
  paymentAmount = 'paymentAmount',
}

const formDataSchema = zfd.formData({
  [FormKey.paymentIntentSecret]: zfd.text(z.string().optional()),
  [FormKey.paymentAmount]: zfd.numeric(z.number().optional()),
});

export default crewAction(({ params: { store }, context, formData }) => {
  const {
    settings: { storefrontId, stripeConfig },
    lineItemClaims,
    order,
    address,
  } = context;

  if (!address) {
    return error(new Error('Context Error: missing address'));
  }

  if (!order) {
    return error(new Error('Context Error: missing order'));
  }

  const { id, email } = order;
  const formDataResult = formDataSchema.safeParse(formData);

  if (!formDataResult.success) {
    return error(
      new Error('Malformed form data', {
        cause: {
          error: formDataResult.error,
          entries: Object.entries(formData),
        },
      }),
    );
  }

  const { paymentIntentSecret, paymentAmount } = formDataResult.data;

  const paymentConfirmation =
    paymentIntentSecret ? Payments.confirmPaymentIntent() : Promise.resolve();

  return paymentConfirmation
    .then((paymentIntentId) => {
      const includeCustomerPayment =
        !!paymentIntentId && !!paymentAmount && !!stripeConfig;

      return api.createCrewClaim({
        params: { storefrontId },
        body: {
          originalStoreOrderId: id,
          billingAddress: address,
          shippingAddress: address,
          customerEmail: email,
          claimType: ClaimType.warranty,
          claimLineItems: pendingClaimsToClaimLineItemCreate(lineItemClaims),
          giftCardIncentiveAmountApplied: 0,
          feeApplied: 0,
          returnShippingAmountApplied: 0,
          exchangeOrderShippingAmountApplied: 0,
          source: 'Customer_App',
          ...(includeCustomerPayment && {
            customerPayment: {
              idFromPlatform: paymentIntentId,
              currencyCode: order.currencyCode,
              stripeConfig,
              //! amount should be in dollars or the ratio equivalent of the currency
              amount: paymentAmount,
            },
          }),
        },
      });
    })
    .then((claim) => {
      // Reset context to clear out any pending claims
      context.reset();
      context.setClaim(claim);

      return redirect(`/${store}/claim/overview/${claim.externalId}`);
    })
    .catch((err) =>
      json<ActionResult>({
        ok: false,
        message: err instanceof Error ? err.message : String(err),
      }),
    );
});

export const useWarrantyReviewActionResult =
  createActionResultHook<ActionResult>();
