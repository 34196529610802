/* eslint-disable react/jsx-props-no-spreading */
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import * as SelectPrimitive from '@radix-ui/react-select';
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { Simplify } from 'type-fest';
import Field from './field';

const SelectContent = forwardRef<
  ElementRef<typeof SelectPrimitive.Content>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={twMerge(
        'relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-md border bg-white shadow-md',
        'data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95',
        'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95',
        'data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        position === 'popper' &&
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        className,
      )}
      position={position}
      {...props}
    >
      <SelectPrimitive.ScrollUpButton className="flex cursor-default items-center justify-center py-1">
        <ChevronUpIcon className="size-4" aria-hidden="true" />
      </SelectPrimitive.ScrollUpButton>
      <SelectPrimitive.Viewport
        className={twMerge(
          'p-1',
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
      <SelectPrimitive.ScrollDownButton className="flex cursor-default items-center justify-center py-1">
        <ChevronDownIcon className="size-4" aria-hidden="true" />
      </SelectPrimitive.ScrollDownButton>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectItem = forwardRef<
  ElementRef<typeof SelectPrimitive.Item>,
  Simplify<
    ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & {
      addon?: ReactNode;
    }
  >
>(({ className, children, addon, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={twMerge(
      'flex w-full cursor-default select-none items-center justify-between gap-2 rounded-sm px-2 py-1.5 text-sm outline-none',
      'focus:bg-gray-100 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className,
    )}
    {...props}
  >
    {addon}
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    <span className="flex h-3.5 w-3.5 items-center justify-center">
      <SelectPrimitive.ItemIndicator>
        <CheckIcon className="size-4" />
      </SelectPrimitive.ItemIndicator>
    </span>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectTrigger = forwardRef<
  ElementRef<typeof SelectPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={twMerge(
      // TODO: figure out how to have the trigger not collapse when there is no value
      // this is a hack to prevent the trigger from collapsing when there is no value
      'after:invisible after:content-["."]',
      'relative flex w-full items-center justify-between rounded-md border border-outline px-4 pb-1 pt-5 text-left [&>span]:line-clamp-1',
      'focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary',
      'data-[state="open"]:border-primary data-[state="open"]:outline-none data-[state="open"]:ring-1 data-[state="open"]:ring-primary',
      'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200',
      className,
    )}
    {...props}
  >
    {children}
    <SelectPrimitive.Icon asChild>
      <ChevronDownIcon
        className="absolute right-4 top-4 size-4 text-gray-500"
        aria-hidden="true"
      />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

// only valueMissing is applicable for select
type ValidationConstraint = Extract<keyof ValidityState, 'valueMissing'>;

type SelectProps = Simplify<
  {
    id: string;
    name: string;
    label: string;
    description?: ReactNode;
    validationMessages?: Record<ValidationConstraint, string>;
  } & Omit<ComponentProps<typeof SelectPrimitive.Root>, 'name' | 'id'>
>;

// TODO: add optional flag
function Select({
  id,
  name,
  label,
  value,
  children,
  required,
  description,
  defaultValue,
  onValueChange,
  validationMessages,
}: SelectProps) {
  // need to control the select value to float the label
  const [fieldValue, setFieldValue] = useState<string | undefined>(
    value ?? defaultValue,
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isValid, setIsValid] = useState(true);

  const triggerRef = useRef<HTMLButtonElement>(null);
  const selectRef = useRef<HTMLSelectElement>();

  useEffect(() => {
    const sibling = triggerRef.current?.nextElementSibling;

    if (!(sibling instanceof HTMLSelectElement)) {
      throw new Error(
        'Select component must be the first child of the SelectRoot component',
      );
    }

    selectRef.current = sibling;
  });

  return (
    <Field.Root className="relative">
      <SelectPrimitive.Root
        required
        defaultValue={fieldValue}
        onValueChange={(selected) => {
          setFieldValue(selected);
          setIsValid(true);
          setErrorMessage(null);
          selectRef.current?.setCustomValidity('');
          onValueChange?.(selected);
        }}
      >
        <SelectTrigger
          aria-required={required}
          ref={triggerRef}
          id={id}
          data-invalid={!isValid ? '' : undefined}
          data-empty={fieldValue ? undefined : ''}
          className={twMerge(
            'group',
            'data-[invalid]:border-error data-[invalid]:data-[state="open"]:border-error data-[invalid]:data-[state="open"]:ring-error data-[invalid]:focus:ring-error',
          )}
        >
          <SelectPrimitive.Value />

          <Field.Label
            htmlFor={id}
            className={twMerge(
              'pointer-events-none absolute left-0 ml-4 px-0 pb-1 pt-0 duration-100 ease-linear',
              // select has value
              '-translate-y-4 text-xs',
              // select is empty and opened
              'group-data-[empty]:group-data-[state="open"]:-translate-y-4 group-data-[empty]:group-data-[state="open"]:text-xs',
              // select is empty
              'group-data-[empty]:-translate-y-2 group-data-[empty]:pb-0 group-data-[empty]:text-sm group-data-[empty]:text-gray-500',
            )}
          >
            {label}
          </Field.Label>
        </SelectTrigger>
        <SelectContent>{children}</SelectContent>
      </SelectPrimitive.Root>
      <input
        aria-hidden
        required={required}
        name={name}
        defaultValue={fieldValue}
        onInvalid={() => {
          setIsValid(false);

          setErrorMessage(
            validationMessages?.valueMissing ??
              selectRef.current?.validationMessage ??
              'Please select an item from the list.',
          );
          selectRef.current?.setCustomValidity(
            validationMessages?.valueMissing ?? '',
          );
        }}
        className="absolute size-0 overflow-hidden whitespace-nowrap border-none p-0"
      />
      {(errorMessage || description) && (
        <Field.HelpText id={`${id}-help-text`} error={!!errorMessage}>
          {errorMessage ?? description}
        </Field.HelpText>
      )}
    </Field.Root>
  );
}

export { Select, SelectItem };
