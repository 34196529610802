import { useState } from 'react';
import { Form } from 'react-router-dom';
import Alert from '~/components/alert';
import Badge from '~/components/badge';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import DL, { DLItem } from '~/components/definition-list';
import Divider from '~/components/divider';
import RadioGroup, { CardOption } from '~/components/form-controls/radio-group';
import LineItem from '~/components/line-item';
import Page from '~/components/page';
import PaymentSummary from '~/components/payment-summary';
import { ExchangeMethod, ResolutionMethod } from '~/utils/constants';
import { useIsLoading } from '~/utils/routing';
import {
  ClaimValues,
  FormKey,
  shouldHavePaymentMethod,
  useReviewActionResult,
} from './return-review-action';
import { ReviewData, useReviewLoaderData } from './return-review-loader';

function SectionTotal({
  label,
  incentive,
  subtotal,
  fee,
  feeLabel,
  shipping,
  total,
  tax,
}: {
  label: string;
  incentive?: ReviewData['fromCustomer']['incentive'];
  subtotal?: ReviewData['fromCustomer']['subtotal'];
  fee?: ReviewData['fromCustomer']['fee'];
  feeLabel?: string;
  shipping?: ReviewData['fromCustomer']['shipping'];
  total: ReviewData['fromCustomer']['total'];
  tax?: ReviewData['fromCustomer']['tax'];
}) {
  return (
    <DL className="mt-6">
      {subtotal !== undefined && (
        <DLItem variant="sm" label="Subtotal" value={subtotal.display} />
      )}
      {tax !== undefined && (
        <DLItem variant="sm" label="Tax" value={tax.display} />
      )}
      {incentive !== undefined && incentive.value !== 0 && (
        <DLItem variant="sm" label="Incentive" value={incentive.display} />
      )}
      {fee !== undefined && fee.value !== 0 && feeLabel && (
        <DLItem variant="sm" label={feeLabel} value={fee.display} />
      )}
      {shipping !== undefined && shipping.value !== 0 && (
        <DLItem variant="sm" label="Shipping" value={shipping.display} />
      )}
      <DLItem variant="lg" label={label} value={total.display} />
    </DL>
  );
}

function RefundSummary({
  balance,
  method,
}: {
  balance: ReviewData['balance'];
  method: ResolutionMethod | null;
}) {
  const value = Math.abs(balance.value);

  if (value === 0) {
    return null;
  }

  return (
    <Card headline="Refund Summary">
      <DL>
        <DLItem variant="lg" label="Refund Value" value={balance.display} />
        {method && (
          <DLItem
            variant="sm"
            label="Method"
            value={
              method === ResolutionMethod.refund ?
                'Original Payment Method'
              : 'Gift Card'
            }
          />
        )}
      </DL>
    </Card>
  );
}

function ExchangeOptions({
  selectedMethod,
  onChange,
}: {
  selectedMethod: ExchangeMethod;
  onChange: (value: ExchangeMethod) => void;
}) {
  const { exchangeMethods } = useReviewLoaderData();
  return (
    <Card headline="Exchange Options">
      <RadioGroup
        label="Exchange Options"
        showLabel={false}
        className="flex flex-col gap-4"
      >
        {exchangeMethods.map((method) => (
          <CardOption
            key={method.type}
            name={FormKey.exchangeMethod}
            value={method.type}
            checked={method.type === selectedMethod}
            onChange={() => onChange(method.type)}
            aria-labelledby={`${method.type}-label`}
            aria-describedby={`${method.type}-description`}
            required
          >
            <div className="flex flex-col gap-2">
              <div id={`${method.type}-label`}>
                {method.label}
                {method.type === ExchangeMethod.instant && (
                  <Badge variant="success" className="ml-2">
                    Fastest
                  </Badge>
                )}
              </div>
              <span id={`${method.type}-description`} className="text-gray-500">
                {method.description}
              </span>
            </div>
          </CardOption>
        ))}
      </RadioGroup>
    </Card>
  );
}

const lineItemDivideStyles =
  'divide-y divide-outline [&>article]:py-4 [&>:first-child]:pt-0 [&>:last-child]:last:pb-0';

export default function Review() {
  const {
    fromCustomer,
    toCustomer,
    balance,
    refundMethod,
    paymentIntentSecret,
    canInstantExchange,
    authorizedAmount,
  } = useReviewLoaderData();
  const isLoading = useIsLoading();
  const actionResult = useReviewActionResult();
  const [exchangeMethod, setExchangeMethod] = useState(
    canInstantExchange ? ExchangeMethod.instant : ExchangeMethod.standard,
  );

  const showPaymentMethod = shouldHavePaymentMethod({
    paymentIntentSecret,
    exchangeMethod,
    balance: balance.value,
    authorizedAmount: authorizedAmount.value,
  });

  // TODO: decompose sections once understand how shop now items will appear
  return (
    <Page
      headline="How does this look?"
      title="Review your decisions before submitting your request"
    >
      <Form method="post" className="flex flex-col gap-4">
        <Card headline="Items you are sending back">
          <div className={lineItemDivideStyles}>
            {fromCustomer.items.map(({ lineItem: item, ...claim }) => (
              <LineItem
                key={item.id}
                variant="small"
                name={item.name ?? ''}
                price={claim.unitPriceDisplay}
                imgSrc={item.imgUrl}
                options={item.optionsFromPlatform}
                quantity={claim.quantity}
              />
            ))}
          </div>
          <Divider />
          <SectionTotal
            label="Return Value"
            subtotal={fromCustomer.subtotal}
            incentive={fromCustomer.incentive}
            fee={fromCustomer.fee}
            feeLabel={fromCustomer.feeLabel}
            shipping={fromCustomer.shipping}
            total={fromCustomer.total}
            tax={fromCustomer.tax}
          />
        </Card>
        {toCustomer && (
          <Card headline="Items you are getting">
            <div className={lineItemDivideStyles}>
              {toCustomer.items.map(({ variant: item, ...claim }) => (
                <LineItem
                  key={item?.idFromPlatform}
                  variant="small"
                  name={item?.name ?? ''}
                  price={claim.unitPriceDisplay}
                  imgSrc={item?.imageUrl}
                  options={item?.options}
                  quantity={claim.quantity}
                />
              ))}
            </div>
            <Divider />
            <SectionTotal
              label="Exchange Value"
              tax={toCustomer.tax}
              subtotal={toCustomer.subtotal}
              shipping={toCustomer.shipping}
              total={toCustomer.total}
            />
          </Card>
        )}

        {canInstantExchange ?
          <ExchangeOptions
            selectedMethod={exchangeMethod}
            onChange={setExchangeMethod}
          />
        : <input
            data-testid="default-exchange-method"
            type="hidden"
            name={FormKey.exchangeMethod}
            value={ExchangeMethod.standard}
          />
        }

        {refundMethod && (
          <RefundSummary balance={balance} method={refundMethod} />
        )}

        {showPaymentMethod && (
          <PaymentSummary
            label="Payment Due"
            amount={
              exchangeMethod === ExchangeMethod.instant ?
                authorizedAmount
              : balance
            }
          />
        )}

        {actionResult && !actionResult.ok && (
          <Alert
            variant="error"
            className="mt-6"
            title="An error occurred while creating the claim. Please try again later."
          >
            <p>{actionResult.message}</p>
          </Alert>
        )}

        <input
          type="hidden"
          name={FormKey.claimValues}
          value={JSON.stringify({
            fromCustomerShipping: fromCustomer.shipping.value,
            fee: fromCustomer.fee?.value ?? 0,
            feeName: fromCustomer.feeLabel,
            incentive: fromCustomer.incentive?.value ?? 0,
            paymentIntentSecret,
            balance: balance.value,
            toCustomerShipping: toCustomer?.shipping.value ?? 0,
            authorizedAmount: authorizedAmount.value,
          } satisfies ClaimValues)}
        />

        <Submit className="mt-6" loading={isLoading}>
          Submit Return Request
        </Submit>
      </Form>
    </Page>
  );
}
