import { useCallback, useState } from 'react';
import { Variant, VariantExchange } from '~/utils/types';

export type Product = VariantExchange[number];

type ProductOption = {
  name: string;
  position: number;
  values: VariantOption[];
};

export type VariantOption = {
  label: string;
  value: string;
  optionName: ProductOption['name'];
  available: boolean;
};

type State = {
  selectedProduct: Product;
  productOptions: ProductOption[];
  selectedVariant: Variant | undefined;
  selectedOptions: Record<ProductOption['name'], VariantOption>;
};

const createProductOption = (option: NonNullable<Product['options']>[number]) =>
  ({
    ...option,
    values: option.values.map(
      (value) =>
        ({
          value,
          label: value,
          optionName: option.name,
          available: true,
        }) satisfies VariantOption,
    ),
  }) satisfies ProductOption;

export const formatVariantExchangeResponse = (res: VariantExchange) =>
  res.map(
    ({ options, ...rest }) =>
      ({
        ...rest,
        options: [...options].sort((a, b) => a.position - b.position),
      }) satisfies Product,
  );

const matchVariantByOption =
  ({ optionName, value }: VariantOption) =>
  (v: Variant) => {
    const option = v.options.find((o) => o.name === optionName);

    return option ? option.value === value : false;
  };
const checkAvailability = (variants: Variant[]) => (option: VariantOption) => ({
  ...option,
  available: variants.some(matchVariantByOption(option)),
});

const updateOptionAvailability =
  (variants: Variant[], selected: VariantOption) =>
  ({ values, ...option }: ProductOption) =>
    ({
      ...option,
      values:
        // don't update the availability of the option group that changed e.g. if color:black is selected, don't update color options
        option.name === selected.optionName ?
          values
          // e.g if color:black is selected, only show the sizes for black as available
        : values.map(
            checkAvailability(
              // only need to check the variants that match the selected option e.g. if color:black is selected, only check the black variants
              variants.filter(matchVariantByOption(selected)),
            ),
          ),
    }) satisfies ProductOption;

const initializeSelectedOptions = (
  productOptions: ProductOption[],
  selectedVariantOptions: Variant['options'],
) =>
  Object.fromEntries(
    selectedVariantOptions.map(({ name: optionName, value }) => {
      const productOption = productOptions.find(
        ({ name }) => name === optionName,
      );

      const selectedOption = productOption?.values.find(
        (option) => option.value === value,
      );

      if (selectedOption === undefined) {
        throw new Error('Selected option not found');
      }

      return [optionName, selectedOption];
    }),
  ) satisfies State['selectedOptions'];

const simulateChangeVariantOption = (
  productOptions: ProductOption[],
  selectedOptions: State['selectedOptions'],
) => {
  const firstProductOption = productOptions[0];
  if (firstProductOption === undefined) {
    throw new Error('No options for selected product');
  }
  const simulatedChangedVariantOption =
    selectedOptions[firstProductOption.name];
  if (simulatedChangedVariantOption === undefined) {
    throw new Error('No options for selected product');
  }

  return simulatedChangedVariantOption;
};

const changeProduct = (selectedProduct: Product): State => {
  const { variants, options } = selectedProduct;
  const productOptions = options.map(createProductOption);
  const firstVariant = variants[0];

  if (firstVariant === undefined) {
    // again, this shouldn't happen because the products should have variants
    throw new Error('No variants for selected product');
  }

  const selectedOptions = initializeSelectedOptions(
    productOptions,
    firstVariant.options,
  );

  return {
    selectedProduct,
    selectedVariant: firstVariant,
    selectedOptions,
    productOptions: productOptions.map(
      updateOptionAvailability(
        variants,
        simulateChangeVariantOption(productOptions, selectedOptions),
      ),
    ),
  };
};

const getSelectVariant = (
  variants: Variant[],
  selectedOptions: VariantOption[],
) =>
  variants.find((variant) =>
    selectedOptions.every((option) => matchVariantByOption(option)(variant)),
  );

const changeVariantOption = (
  state: State,
  selectedOption: VariantOption,
): State => {
  const { variants } = state.selectedProduct;

  const updatedSelectedOptions = {
    ...state.selectedOptions,
    [selectedOption.optionName]: selectedOption,
  };

  return {
    ...state,
    selectedOptions: updatedSelectedOptions,
    selectedVariant: getSelectVariant(
      variants,
      Object.values(updatedSelectedOptions),
    ),
    productOptions: state.productOptions.map(
      updateOptionAvailability(variants, selectedOption),
    ),
  };
};

export const useExchangeProductData = (products: Product[]) => {
  const initialProduct = products[0];

  if (initialProduct === undefined) {
    throw new Error('No products in exchange data');
  }

  const [state, setState] = useState<State>(changeProduct(initialProduct));

  const onProductChange = useCallback(
    (productId: Product['idFromPlatform']) => {
      const product = products.find(
        ({ idFromPlatform }) => idFromPlatform === productId,
      );
      if (product === undefined) {
        throw new Error('No product found');
      }

      setState(changeProduct(product));
    },
    [products],
  );

  const onVariantOptionChange = useCallback(
    (optionName: ProductOption['name']) =>
      (selectedValue: VariantOption['value']) => {
        const productOption = state.productOptions.find(
          ({ name }) => name === optionName,
        );
        const variantOption = productOption?.values.find(
          ({ value }) => value === selectedValue,
        );

        if (variantOption === undefined) {
          throw new Error('No variant option found');
        }

        setState((previousState) =>
          changeVariantOption(previousState, variantOption),
        );
      },
    [state.productOptions],
  );

  return {
    ...state,
    products,
    onProductChange,
    onVariantOptionChange,
  };
};
