import { StatusCodes } from 'http-status-codes';
import { z } from 'zod';
import { zfd } from 'zod-form-data';
import { isGiftCardClaim, isRefundClaim } from '~/utils/compute';
import { ResolutionMethod } from '~/utils/constants';
import {
  createActionResultHook,
  crewAction,
  error,
  redirect,
} from '~/utils/routing';
import { ActionResult, GiftCardClaim, RefundClaim } from '~/utils/types';

export const FIELD_KEY = 'resolution';

const formSchema = zfd.formData({
  [FIELD_KEY]: zfd.text(
    z.union([
      z.literal(ResolutionMethod.refund),
      z.literal(ResolutionMethod.giftCard),
    ]),
  ),
});

export default crewAction(({ formData, context }) => {
  const result = formSchema.safeParse(formData);

  if (!result.success) {
    return error(
      new Error('Malformed form data', {
        cause: {
          error: result.error,
          entries: Array.from(formData.entries()),
        },
      }),
    );
  }

  const { resolution } = result.data;
  const predicate =
    resolution === ResolutionMethod.giftCard ? isRefundClaim : isGiftCardClaim;

  context.lineItemClaims
    // kind of forcing the type here -- probably can be inferred with the TS v5.5
    .filter((claim): claim is GiftCardClaim | RefundClaim => predicate(claim))
    .forEach((claim) => {
      context.upsertLineItemClaim({
        ...claim,
        requestedResolutionMethodEnum: resolution,
      });
    });

  return redirect('../address', StatusCodes.SEE_OTHER);
});

export const useRefundOptionsActionResult =
  createActionResultHook<ActionResult<typeof FIELD_KEY>>();
