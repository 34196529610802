import { Form, useNavigation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Badge from '~/components/badge';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import { Select, SelectItem } from '~/components/form-controls/select';
import LinkButton from '~/components/link-button';
import ProductImage from '~/components/product-image';
import { PRODUCT_NOT_FOUND_IMG_URL, ResolutionMethod } from '~/utils/constants';
import { useIsLoading } from '~/utils/routing';
import {
  Product,
  VariantOption,
  useExchangeProductData,
} from './exchange-options';
import { FormKey } from './resolution-action';
import { useResolutionData } from './resolution-loader';

function VariantSelectOption({ option }: { option: VariantOption }) {
  return (
    <SelectItem value={option.value}>
      <div
        className={twMerge(
          'flex flex-wrap items-baseline gap-2',
          !option.available && 'opacity-50',
        )}
      >
        <span className={twMerge(!option.available && 'line-through')}>
          {option.label}
        </span>
        {!option.available && <span className="text-xs">unavailable</span>}
      </div>
    </SelectItem>
  );
}

function ProductSelectOption({ product }: { product: Product }) {
  const imagePrefix = (
    <img
      src={product.imageUrl ?? PRODUCT_NOT_FOUND_IMG_URL}
      aria-hidden
      className="size-8 self-start rounded border border-outline"
      alt={`Image for ${product.name}`}
    />
  );

  return (
    <SelectItem value={product.idFromPlatform} addon={imagePrefix}>
      {product.name}
    </SelectItem>
  );
}

function VariantExchange() {
  const navigation = useNavigation();
  const isLoading = useIsLoading();
  const { claim, exchangeProducts } = useResolutionData();
  const { onProductChange, onVariantOptionChange, ...state } =
    useExchangeProductData(exchangeProducts);
  const {
    lineItem: { name: defaultName, imgUrl: defaultImageUrl },
  } = claim;
  const displayProductSelect =
    state.products.length > 1 || state.selectedProduct.hasOnlyDefaultVariant;
  return (
    <Card headline="What would you like as an exchange?">
      <div className="gap-6 md:grid md:grid-cols-2">
        <div className="flex aspect-square items-center justify-center">
          <ProductImage
            variant="full"
            name={state.selectedVariant?.label ?? defaultName}
            src={state.selectedVariant?.imageUrl ?? defaultImageUrl}
          />
        </div>
        <Form
          className="flex flex-col gap-4"
          method="post"
          name="variantExchange"
          aria-label="variant exchange"
        >
          {displayProductSelect && (
            <Select
              id="product"
              name="product"
              label="Product"
              onValueChange={onProductChange}
              value={state.selectedProduct.idFromPlatform}
            >
              {state.products.map((product) => (
                <ProductSelectOption
                  key={product.idFromPlatform}
                  product={product}
                />
              ))}
            </Select>
          )}

          {!state.selectedProduct.hasOnlyDefaultVariant &&
            state.productOptions.map(({ name, values }) => (
              <Select
                id={name}
                name={name}
                label={name}
                onValueChange={onVariantOptionChange(name)}
                value={state.selectedOptions[name]?.label}
                key={`${state.selectedProduct.idFromPlatform}-${name}`}
              >
                {values.map((option) => (
                  <VariantSelectOption key={option.value} option={option} />
                ))}
              </Select>
            ))}

          <input
            type="hidden"
            hidden
            name={FormKey.resolution}
            data-testid="exchangeResolution"
            defaultValue={ResolutionMethod.variantExchange}
          />
          <input
            type="hidden"
            hidden
            name={FormKey.variant}
            data-testid={FormKey.variant}
            defaultValue={JSON.stringify(state.selectedVariant)}
          />

          <Submit
            disabled={!state.selectedVariant}
            variant={state.selectedVariant ? 'filled' : 'outlined'}
            className="mt-6 md:w-full"
            loading={isLoading && navigation.formData?.has(FormKey.variant)}
          >
            {state.selectedVariant ? 'Choose This Product' : 'Unavailable'}
          </Submit>
        </Form>
      </div>
    </Card>
  );
}

function Credit() {
  const navigation = useNavigation();
  const isLoading = useIsLoading();
  const { canExchange, creditResolutionMethod, canCredit } =
    useResolutionData();

  return (
    <Card
      headline={
        canExchange ? 'Or would you like your money back?' : 'Next Steps'
      }
      headerContent={
        canExchange && <Badge variant="danger">Fees may apply</Badge>
      }
    >
      <p className="text-gray-500">
        {`You can receive the value of your return in the form of a refund${canCredit ? ' or store credit' : ''}, but restrictions and handling fees may apply.`}
      </p>
      <Form method="post" name="credit" aria-label="credit">
        <input
          type="hidden"
          hidden
          data-testid="creditResolution"
          name={FormKey.resolution}
          value={creditResolutionMethod}
        />
        <Submit
          variant="outlined"
          className="w-full md:w-1/2"
          loading={isLoading && !navigation.formData?.has(FormKey.variant)}
        >
          Return for money back
        </Submit>
      </Form>
    </Card>
  );
}

function EscapeHatch() {
  const { canExchange, orderId } = useResolutionData();

  // ? in the case that a customer doesn't want a variant exchange, we show the escape hatch indicating to reach out to the merchant
  // ? isExchangeOnly should allow variant exchange OR store credit
  const message =
    canExchange ?
      "If an exchange doesn't fit your needs, please reach out to our team."
    : "It looks like we can't handle this return automatically, please reach out to our team.";

  return (
    <Card headline="Need help?">
      <p>{message}</p>
      <LinkButton
        className="mt-6 w-full md:w-1/2"
        variant="outlined"
        to={`/order/${orderId}`}
      >
        Back to Order
      </LinkButton>
    </Card>
  );
}

export default function ClaimResolution() {
  const { canExchange, canCredit } = useResolutionData();

  return (
    <>
      {canExchange && <VariantExchange />}
      {canCredit && <Credit />}
      {!canCredit && <EscapeHatch />}
    </>
  );
}
