import { StatusCodes } from 'http-status-codes';
import { validateAddressData } from '~/utils/address';
import { crewAction, json, redirect } from '~/utils/routing';
import { ActionResult, Address } from '~/utils/types';

export default crewAction(({ formData, context }) => {
  const result = validateAddressData(formData);

  if (!result.ok) {
    return json<ActionResult<keyof Address>>({
      ok: false,
      form: result.errors,
    });
  }

  context.setAddress(result.data);

  return redirect(`..`, StatusCodes.SEE_OTHER);
});
