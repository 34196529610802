import { ChevronRightIcon } from '@heroicons/react/20/solid';
import {
  ArrowsRightLeftIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { Link, useParams } from 'react-router-dom';
import Button from '~/components/button';
import Card from '~/components/card';
import Disclosure from '~/components/disclosure';
import Divider from '~/components/divider';
import LinkButton from '~/components/link-button';
import Page from '~/components/page';
import { ClaimRollupStatuses } from '~/utils/constants';
import { formatDate } from '~/utils/formatters';
import { Claim } from '~/utils/types';
import { useOrderHubData } from './order-loader';
import Tracking from './order-tracking-component';

// ----------- components ----------------------

function OrderClaims({ claims }: { claims: Claim[] }) {
  return (
    <Disclosure
      renderSummary={
        <span className="flex items-center gap-2 text-sm font-medium text-gray-800">
          <InformationCircleIcon className="h-6 w-6 shrink-0" />
          This order has existing requests
        </span>
      }
    >
      <ul className="divide-y divide-outline">
        {claims.map((claim) => (
          <li
            key={claim.externalId}
            className="relative grid w-full grid-cols-[auto_auto_20px] grid-rows-2 gap-x-2 py-2 last:pb-0"
          >
            <span className="text-sm font-semibold text-gray-800">
              {claim.externalId}
            </span>
            <span className="col-start-1 row-start-2 text-xs text-gray-500">
              {claim.claimType}
            </span>

            <span className="text-right text-sm font-semibold text-gray-800">
              {formatDate(claim.createdOn)}
            </span>
            <span className="col-start-2 row-start-2 text-right text-xs text-gray-500">
              {ClaimRollupStatuses[claim.claimRollup.code]}
            </span>

            <ChevronRightIcon
              className="col-start-3 row-span-2 h-5 w-5 self-center text-gray-400"
              aria-hidden="true"
            />
            <Link
              className="absolute inset-x-0 -top-px bottom-0"
              to={`../../claim/overview/${claim.externalId}`}
              aria-label={`Go to claim ${claim.externalId}`}
            />
          </li>
        ))}
      </ul>
    </Disclosure>
  );
}

function GspHelp({
  wasShippingProtected,
  iconImgUrl,
  showStartClaim,
}: {
  wasShippingProtected: boolean;
  iconImgUrl: string;
  showStartClaim: boolean;
}) {
  // ! Temp until reorder is ready
  const params = useParams();

  return (
    <section className="flex flex-col gap-4">
      <div className="flex flex-col items-start gap-x-4 md:flex-row md:items-center md:justify-between">
        <div className="mb-4 flex items-center gap-x-4 md:mb-0">
          <img
            className="h-16 w-16 shrink-0"
            alt="Green Shipping Protection"
            src={iconImgUrl}
            aria-hidden
          />
          <div>
            <h3 className="col-start-2 row-start-1 font-medium text-gray-800">
              Shipping Issues
            </h3>
            <p className="col-start-2 row-start-2 text-xs text-gray-500 md:text-sm">
              <span className="hidden md:inline">
                {wasShippingProtected && 'Your order has shipping protection'}
              </span>{' '}
              {wasShippingProtected ?
                'Report a shipping issue to request a replacement.'
              : 'Report a shipping issue to get help.'}
            </p>
          </div>
        </div>

        <LinkButton
          relative="path"
          to={`/${String(params.store)}/reorder/${String(
            params.idFromPlatform,
          )}`}
          className="w-full shrink-0 md:w-60"
        >
          Report Shipping Issue
        </LinkButton>
      </div>

      {showStartClaim && <Divider />}
    </section>
  );
}

const when = (condition: boolean, str: string) => (condition ? str : '');

function StartClaim() {
  const {
    isOrderFulfilled,
    pendingClaimType,
    areReturnsEnabled,
    areWarrantiesEnabled,
  } = useOrderHubData();

  const title = `${when(areReturnsEnabled, 'Returns')}${when(
    areReturnsEnabled && areWarrantiesEnabled,
    ' & ',
  )}${when(areWarrantiesEnabled, 'Warranties')}`;

  const fulfilledText = `Create a request to ${when(
    areReturnsEnabled,
    'return an item',
  )}${when(areReturnsEnabled && areWarrantiesEnabled, ' or ')}${when(
    areWarrantiesEnabled,
    'start a warranty claim.',
  )}`;

  const description =
    isOrderFulfilled ? fulfilledText : (
      "It doesn't look like your order has been delivered yet. Please check back later."
    );

  return (
    <section className="flex items-center justify-between gap-x-4">
      <div className="flex w-full flex-col items-start justify-between gap-x-4 md:flex-row md:items-center">
        <div className="mb-4 flex basis-3/5 items-center gap-x-4 md:mb-0">
          <div className="flex w-16 shrink-0 justify-center">
            <div className="flex h-16 w-16 items-center justify-center rounded-full border-2 border-outline bg-gray-50">
              <ArrowsRightLeftIcon className="h-8 w-8 shrink-0 text-primary" />
            </div>
          </div>
          <div>
            <h3 className="col-start-2 row-start-1 font-medium text-gray-800">
              {title}
            </h3>

            <p className="col-start-2 row-start-2 text-xs text-gray-500 md:text-sm">
              {description}
            </p>
          </div>
        </div>

        <div className="w-full shrink-0 space-y-2 md:w-60">
          {isOrderFulfilled ?
            <LinkButton variant="filled" to="new-request" className="w-full">
              Start New Request
            </LinkButton>
          : <Button disabled className="w-full">
              Start New Request
            </Button>
          }
          {pendingClaimType && (
            <LinkButton
              variant="text"
              to={pendingClaimType}
              className="w-full text-sm text-primary"
            >
              Resume Request
            </LinkButton>
          )}
        </div>
      </div>
    </section>
  );
}

// different states for tracking, for an order that has not been fulfilled
function NoTrackingData({ isOrderFulfilled }: { isOrderFulfilled: boolean }) {
  return isOrderFulfilled ?
      <div className="flex items-center gap-2 border-none text-sm font-medium text-gray-800">
        <InformationCircleIcon className="h-6 w-6 shrink-0" />
        Tracking information not available.
      </div>
    : <div className="flex items-center gap-2 border-none text-sm font-medium text-gray-800">
        <div className="text-gray-800">
          <p className="text-xs font-medium uppercase">TRACKING INFORMATION</p>
          <p className="text-2xl font-medium tracking-tight md:text-4xl">
            Order Placed
          </p>
          <p className="text-sm text-gray-500 md:text-base">
            Getting Ready To Ship
          </p>
        </div>
      </div>;
}

export default function OrderHub() {
  const {
    trackers,
    wasShippingProtected,
    shippingProtectionImgIconUrl,
    existingClaims,
    areReturnsEnabled,
    areWarrantiesEnabled,
    isOrderFulfilled,
    isUnprotectedShippingClaimPermitted,
  } = useOrderHubData();

  const showStartClaim = areReturnsEnabled || areWarrantiesEnabled;
  const showGspStartClaim =
    wasShippingProtected || isUnprotectedShippingClaimPermitted;

  return (
    <Page headline="Order Overview" showHeadline={false}>
      <Card>
        {trackers.length ?
          <div className="flex flex-col gap-4">
            <Tracking trackers={trackers} />
          </div>
        : <NoTrackingData isOrderFulfilled={isOrderFulfilled} />}
      </Card>
      {(showStartClaim || existingClaims || showGspStartClaim) && (
        <Card headline="Order Claim Information" showHeadline={false}>
          <div className="flex flex-col gap-4">
            {existingClaims && (
              <>
                <OrderClaims claims={existingClaims} />
                <Divider />
              </>
            )}

            {showGspStartClaim && (
              <GspHelp
                wasShippingProtected={wasShippingProtected}
                showStartClaim={showStartClaim}
                iconImgUrl={shippingProtectionImgIconUrl}
              />
            )}
            {showStartClaim && <StartClaim />}
          </div>
        </Card>
      )}
    </Page>
  );
}
