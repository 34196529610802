import { StatusCodes } from 'http-status-codes';
import { z } from 'zod';
import { zfd } from 'zod-form-data';
import Payments from '~/stores/payments';
import api from '~/utils/api';
import { pendingClaimsToClaimLineItemCreate } from '~/utils/claim-line-items';
import { ClaimType, ExchangeMethod } from '~/utils/constants';
import {
  createActionResultHook,
  crewAction,
  error,
  json,
  redirect,
} from '~/utils/routing';
import { shipmentCreateSchema } from '~/utils/schemas';
import { ActionResult, Claim, ShipmentMethod } from '~/utils/types';

export enum FormKey {
  claimValues = 'claimValues',
  exchangeMethod = 'exchangeMethod',
}

export const shouldHavePaymentMethod = ({
  paymentIntentSecret,
  exchangeMethod,
  balance,
  authorizedAmount,
}: {
  paymentIntentSecret?: string;
  exchangeMethod?: string;
  balance: number;
  authorizedAmount: number;
}) =>
  Boolean(paymentIntentSecret) &&
  ((authorizedAmount > 0 && exchangeMethod === ExchangeMethod.instant) ||
    (balance > 0 && exchangeMethod === ExchangeMethod.standard));

const claimValues = z.preprocess(
  (value) => JSON.parse(String(value)),
  z.object({
    feeName: z.string().optional(),
    incentive: z.coerce.number().transform(Math.abs),
    toCustomerShipping: z.coerce.number().transform(Math.abs),
    fromCustomerShipping: z.coerce.number().transform(Math.abs),
    fee: z.coerce.number().transform(Math.abs),
    balance: z.coerce.number().transform(Math.abs),
    paymentIntentSecret: z.string().optional(),
    authorizedAmount: z.coerce.number().transform(Math.abs),
  }),
);

export type ClaimValues = z.infer<typeof claimValues>;

const formDataSchema = zfd.formData({
  [FormKey.claimValues]: zfd.text(claimValues),
  [FormKey.exchangeMethod]: zfd.text(z.nativeEnum(ExchangeMethod).optional()),
});

const submitShipment =
  (storefrontId: string, returnShipment: ShipmentMethod) => (claim: Claim) => {
    const parseResult = shipmentCreateSchema.safeParse({
      claimId: claim.id,
      returnShipment,
    });

    if (!parseResult.success) {
      return error(
        new Error('Invalid return shipment', {
          cause: { error: parseResult.error },
        }),
      );
    }

    return api
      .createShipment({ params: { storefrontId }, body: parseResult.data })
      .then((shipment) => ({
        ...claim,
        claimReturnShipments: shipment ? [shipment] : [],
      }));
  };

export default crewAction(({ context, formData, params: { store } }) => {
  const { storefrontId, stripeConfig } = context.settings;
  const { lineItemClaims, order, address, returnMethod } = context;

  if (!order) {
    return error(new Error('Context Error: Missing order'));
  }
  if (!address) {
    return error(new Error('Context Error: Missing address'));
  }

  if (!returnMethod) {
    return error(
      new Error('Context Error: Missing From Customer return method'),
    );
  }

  const formDataResult = formDataSchema.safeParse(formData);

  if (!formDataResult.success) {
    return error(
      new Error('Malformed form data', {
        cause: {
          error: formDataResult.error,
          entries: Array.from(Object.entries(formData)),
        },
      }),
    );
  }

  const {
    claimValues: {
      incentive: giftCardIncentiveAmountApplied,
      toCustomerShipping: exchangeOrderShippingAmountApplied,
      fromCustomerShipping: returnShippingAmountApplied,
      fee,
      feeName,
      balance,
      paymentIntentSecret,
      authorizedAmount,
    },
    exchangeMethod,
  } = formDataResult.data;

  const { id, email: customerEmail } = order;

  const claimLineItems = pendingClaimsToClaimLineItemCreate(lineItemClaims);
  const shouldConfirmPayment = shouldHavePaymentMethod({
    paymentIntentSecret,
    exchangeMethod,
    balance,
    authorizedAmount,
  });

  const confirmation =
    shouldConfirmPayment ?
      Payments.confirmPaymentIntent()
    : Promise.resolve(null);

  return confirmation
    .then((paymentIntentId) =>
      api.createCrewClaim({
        params: { storefrontId },
        body: {
          claimLineItems,
          claimType: ClaimType.return,
          originalStoreOrderId: id,
          customerEmail,
          billingAddress: address,
          shippingAddress: address,
          giftCardIncentiveAmountApplied,
          exchangeOrderShippingAmountApplied,
          feeApplied: fee,
          feeName,
          returnShippingAmountApplied,
          source: 'Customer_App',
          // logically if shouldConfirmPayment is true, then paymentIntentId and stripeConfig should exist, but TS complains.
          ...(stripeConfig &&
            paymentIntentId &&
            shouldConfirmPayment && {
              customerPayment: {
                idFromPlatform: paymentIntentId,
                currencyCode: order.currencyCode,
                stripeConfig,
                // ! amount = non-refundable portion of the balance (IE, fees, shipping, etc.)
                //! balance should be in dollars or the ratio equivalent of the currency
                amount: balance,
                // ! authorizedAmount = refundable portion of the balance (IE the instant exchange amount ) OR undefined if it does not exist
                // ! We will know to capture immediately or wait based on the existence of authorizedAmount
                authorizedAmount:
                  exchangeMethod === ExchangeMethod.instant ?
                    authorizedAmount
                  : undefined,
              },
            }),
        },
      }),
    )
    .then(submitShipment(storefrontId, returnMethod))
    .then((claim: Claim) => {
      context.reset();
      context.setClaim(claim);

      return redirect(
        `/${store}/claim/overview/${claim.externalId}`,
        StatusCodes.SEE_OTHER,
      );
    })
    .catch((err) =>
      json<ActionResult>({
        ok: false,
        message: err instanceof Error ? err.message : String(err),
      }),
    );
});

export const useReviewActionResult = createActionResultHook<ActionResult>();
