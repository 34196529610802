import Payments from '~/stores/payments';
import api from '~/utils/api';
import { pendingLineItemClaimToAutomationLineItem } from '~/utils/claim-line-items';
import { formatDisplayValue, toCurrency } from '~/utils/formatters';
import {
  createLoaderDataHook,
  crewLoader,
  json,
  redirect,
} from '~/utils/routing';
import { LineItemClaim } from '~/utils/types';

export type WarrantyReviewData = {
  claims: LineItemClaim[];
  paymentIntentSecret?: string;
  fee?: {
    amount: ReturnType<ReturnType<typeof formatDisplayValue<number>>>;
    displayName: string;
  };
};

export default crewLoader(
  async ({
    params: { store },
    context: { lineItemClaims, order, settings, returnMethod, toCustomerRate },
  }) => {
    if (!order || lineItemClaims.length === 0) {
      return redirect(`/${store}`);
    }

    const lineItems = lineItemClaims.map((claim) =>
      pendingLineItemClaimToAutomationLineItem(claim),
    );

    const monetarySettings = await api.checkBeforeClaimSubmission({
      params: { storefrontId: settings.storefrontId },
      body: {
        kind: 'beforeClaimSubmissionWarranty' as const,
        lineItems,
        fromCustomer: returnMethod,
        toCustomer: toCustomerRate,
      },
    });

    const paymentIntentSecret =
      monetarySettings.fee && settings.stripeConfig ?
        await Payments.createPaymentIntent({
          storefrontId: settings.storefrontId,
          amount: monetarySettings.fee.amount,
          currencyCode: order.currencyCode,
          stripeConfig: settings.stripeConfig,
          customerEmail: order.email,
        })
      : undefined;

    return json<WarrantyReviewData>({
      claims: lineItemClaims,
      paymentIntentSecret,
      ...(monetarySettings.fee && {
        fee: {
          amount: formatDisplayValue(toCurrency(order.currencyCode))(
            monetarySettings.fee.amount,
          ),
          displayName: monetarySettings.fee.displayName,
        },
      }),
    });
  },
);

export const useWarrantyReviewData = createLoaderDataHook<WarrantyReviewData>();
