import { StatusCodes } from 'http-status-codes';
import { validateAddressData } from '~/utils/address';
import { ClaimType } from '~/utils/constants';
import {
  createActionResultHook,
  crewAction,
  json,
  redirect,
} from '~/utils/routing';
import { ActionResult, Address } from '~/utils/types';

export default crewAction(({ formData, context }) => {
  const result = validateAddressData(formData);

  if (!result.ok) {
    return json<ActionResult<keyof Address>>({
      ok: false,
      form: result.errors,
    });
  }

  context.setAddress(result.data);

  const isWarranty = context.lineItemClaims.some(
    (claim) => claim.claimType === ClaimType.warranty,
  );

  const nextPage = isWarranty ? `../review/warranty` : `../return-methods`;

  return redirect(nextPage, StatusCodes.SEE_OTHER);
});

export const useAddressActionResult = createActionResultHook<ActionResult>();
