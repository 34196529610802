import { CORSO_URL } from '~/utils/constants';
import {
  createLoaderDataHook,
  crewLoader,
  json,
  redirect,
} from '~/utils/routing';

export type OrderLookupLoaderData = {
  exchangeOrderPrefix: string | undefined;
  orderLookupDetailText: string | undefined;
  orderLookupLabel: string;
  startRegistrationText?: string;
};

export default crewLoader(({ context }) => {
  const {
    isActive,
    storeUrl = CORSO_URL,
    exchangeOrderPrefix,
    registrations: {
      isEnabled: isRegistrationEnabled,
      isStartWarrantyClaimEnabled,
    },
    theme: { orderLookupDetailText, orderLookupRegistrationText },
  } = context.settings;
  if (!isActive) {
    return redirect(`inactive?redirect=${encodeURI(storeUrl)}`);
  }

  const offerRegistration =
    isRegistrationEnabled && isStartWarrantyClaimEnabled;

  const orderLookupLabel =
    offerRegistration ? `Order / Registration Number` : `Order Number`;

  const startRegistrationText =
    offerRegistration ?
      (orderLookupRegistrationText ?? `Didn't purchase from our online store?`)
    : undefined;

  return json<OrderLookupLoaderData>({
    exchangeOrderPrefix,
    orderLookupDetailText,
    orderLookupLabel,
    startRegistrationText,
  });
});

export const useOrderLookupData = createLoaderDataHook<OrderLookupLoaderData>();
