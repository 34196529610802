import api from '~/utils/api';
import { ClaimType } from '~/utils/constants';
import {
  createLoaderDataHook,
  crewLoader,
  error,
  json,
  withDraftClaim,
} from '~/utils/routing';
import {
  AfterReasonSelectionEvent,
  type DraftLineItemClaim,
} from '~/utils/types';

type CustomFields = NonNullable<
  AfterReasonSelectionEvent['eventCustomFields']
>['params']['customFields'];

export type ClaimMetaData = {
  claim: DraftLineItemClaim;
  showDefaultCommentField: boolean;
  requiredMediaCount: number;
  customFields: CustomFields;
  mediaUploadInstructions?: string;
  isDefaultCommentFieldRequired?: boolean;
};

export default crewLoader(
  withDraftClaim(
    ({
      claim,
      context: {
        settings: { storefrontId, isClaimImageFromCustomerRequired },
      },
    }) => {
      if (!claim.reason) {
        return error(new Error('Missing claim reason.'));
      }
      return api
        .checkAfterReasonSelection({
          params: { storefrontId },
          query: {
            lineItemId: claim.lineItem.id,
            reasonId: claim.reason.id,
            claimType: claim.claimType,
            reasonCategoryCode: claim.reason.category,
            ...(claim.reason.detail && {
              reasonDetailId: claim.reason.detail.id,
            }),
          },
        })
        .then(({ eventCustomFields }) => {
          const requireAssets =
            eventCustomFields?.params.isMediaUploadRequired ??
            (isClaimImageFromCustomerRequired ||
              claim.claimType === ClaimType.warranty);

          // if require assets is true,
          // then the minimumMediaUploadAmount is the provided value or 1
          // if require assets is false, then the minimumMediaUploadAmount is 0
          const requiredMediaCount =
            requireAssets ?
              (eventCustomFields?.params.minimumMediaUploadAmount ?? 1)
            : 0;

          return json<ClaimMetaData>({
            customFields: eventCustomFields?.params.customFields ?? [],
            mediaUploadInstructions:
              eventCustomFields?.params.mediaUploadInstructions,
            requiredMediaCount,
            showDefaultCommentField:
              eventCustomFields?.params.showDefaultCommentField ?? true,
            claim,
            isDefaultCommentFieldRequired:
              eventCustomFields?.params.showDefaultCommentField ?
                eventCustomFields.params.isDefaultCommentFieldRequired
              : false,
          });
        });
    },
  ),
);

export const useMetaData = createLoaderDataHook<ClaimMetaData>();
