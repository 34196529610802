import { StatusCodes } from 'http-status-codes';
import { z } from 'zod';
import api from '~/utils/api';
import { ClaimType, ResolutionMethod } from '~/utils/constants';
import {
  createLoaderDataHook,
  crewLoader,
  error,
  json,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import { lineItemClaimSchema } from '~/utils/schemas';
import { DraftLineItemClaim } from '~/utils/types';
import { formatVariantExchangeResponse } from './exchange-options';

export type ClaimResolutionData = {
  exchangeProducts: ReturnType<typeof formatVariantExchangeResponse>;
  claim: DraftLineItemClaim;
  canExchange: boolean;
  orderId: string;
  canCredit: boolean;
  creditResolutionMethod?: ResolutionMethod.giftCard | ResolutionMethod.refund;
};

const paramsSchema = z.object({
  idFromPlatform: z.string(),
  claimType: z.nativeEnum(ClaimType),
  store: z.string(),
});

export default crewLoader(
  withDraftClaim(({ claim, context, params }) => {
    const { idFromPlatform, claimType, store } = paramsSchema.parse(params);

    if (!context.order) {
      return redirect(`/order/${idFromPlatform}/${claimType}`);
    }

    if (claim.claimType === ClaimType.warranty) {
      const parsedClaim = lineItemClaimSchema.parse({
        ...claim,
        requestedResolutionMethodEnum: ResolutionMethod.warrantyReview,
      });
      context.upsertLineItemClaim(parsedClaim);
      context.clearDraftClaim();

      return redirect(
        `/${store}/order/${idFromPlatform}/${claimType}`,
        StatusCodes.SEE_OTHER,
      );
    }

    const { storefrontId } = context.settings;

    const { idFromPlatform: orderIdFromPlatform } = context.order;
    const { isRefundEnabled, isGiftCardEnabled, isExchangeEnabled } =
      context.settings.return;

    const {
      return: {
        isExchangeOnly,
        // isEligible - as in within the time window
        exchange: { isEligible: isExchangeEligible },
        refund: { isEligible: isRefundEligible },
        giftCard: { isEligible: isGiftCardEligible },
      },
      productIdFromPlatform,
    } = claim.lineItem;

    const canExchange = isExchangeEnabled && isExchangeEligible;
    const canCredit =
      ((isRefundEnabled && isRefundEligible) ||
        (isGiftCardEnabled && isGiftCardEligible)) &&
      !isExchangeOnly;

    const variantData =
      canExchange && productIdFromPlatform ?
        api
          .fetchProductVariantInfo({
            params: {
              storefrontId,
              idFromPlatform: productIdFromPlatform,
            },
          })
          .then(formatVariantExchangeResponse)
      : Promise.resolve([]);

    return variantData
      .then((data) =>
        json<ClaimResolutionData>({
          exchangeProducts: data,
          claim,
          canExchange: canExchange && data.length > 0,
          orderId: `${orderIdFromPlatform}`,
          canCredit,
          ...(canCredit && {
            creditResolutionMethod:
              isRefundEnabled && isRefundEligible ?
                ResolutionMethod.refund
              : ResolutionMethod.giftCard,
          }),
        }),
      )
      .catch((err: unknown) =>
        error(
          err instanceof Error ? err : (
            new Error('Unknown error', { cause: { originalError: err } })
          ),
        ),
      );
  }),
);

export const useResolutionData = createLoaderDataHook<ClaimResolutionData>();
