/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod';
import { zfd } from 'zod-form-data';
import api from '~/utils/api';
import { json, redirect, registrationAction } from '~/utils/routing';
import { ActionResult } from '~/utils/types';

export const FORM_KEY = 'variants';
const formSchema = zfd.formData({
  [FORM_KEY]: zfd.repeatable(
    z
      .array(
        z.object({
          id: zfd.numeric(),
          quantity: zfd.numeric(z.number().min(1)),
        }),
      )
      .min(1),
  ),
});

export default registrationAction(({ context, formData }) => {
  const {
    draftRegistration: draft,
    settings: { storefrontId },
  } = context;

  // if there is no draft registration
  // redirect to the lookup page
  if (!draft) {
    return redirect(`..`);
  }

  const parsedFormData = formSchema.safeParse(formData);
  if (!parsedFormData.success) {
    return json<ActionResult>({
      ok: false,
      message: 'Invalid form data',
    });
  }

  const proofOfPurchaseFilenames =
    draft.proofOfPurchaseAssets?.map((asset) => asset.name) ?? [];

  return api
    .createRegistration({
      params: { storefrontId },
      body: {
        firstName: draft.firstName,
        lastName: draft.lastName,
        storeSalesChannelId: draft.storeSalesChannel.id,
        estimatedPurchaseDate: draft.estimatedPurchaseDate,
        email: draft.email,
        storeProductVariants: parsedFormData.data.variants,
        address: draft.address ?? undefined,
        proofOfPurchaseFilenames,
      },
    })
    .then((registration) => {
      context.setRegistration(registration);
      context.clearDraftRegistration();
      return redirect(`../overview`);
    });
});
