import { logError } from './observability';

const currencyFormatter = (currency: string) =>
  new Intl.NumberFormat('default', {
    style: 'currency',
    currency,
  });

const ConjunctiveListFormatter = new Intl.ListFormat('default', {
  style: 'long',
  type: 'conjunction',
});

export const toCurrency = (currencyCode: string) => {
  const formatter = currencyFormatter(currencyCode);

  return (value: number) => formatter.format(value);
};

export const toConjunctiveList = (value: string[]) =>
  ConjunctiveListFormatter.format(value);

const StandardDateFormatter = new Intl.DateTimeFormat('default', {
  dateStyle: 'medium',
});
const UTCDateFormatter = new Intl.DateTimeFormat('default', {
  dateStyle: 'medium',
  timeStyle: 'short',
  timeZone: 'UTC',
});
const invalidDatePlaceholder = '--';

const parseDate = (value: string) => {
  const date = new Date(value);

  if (date.toString() === 'Invalid Date') {
    logError(new Error(`Invalid date: ${value}`));
    return null;
  }

  return date;
};

export const formatDate = (value: string) => {
  const date = parseDate(value);

  if (!date) {
    return invalidDatePlaceholder;
  }

  try {
    return StandardDateFormatter.format(date);
  } catch (e) {
    logError(e);
    return invalidDatePlaceholder;
  }
};

export const formatDateTimeUtc = (value: string) => {
  const date = parseDate(value);

  if (!date) {
    return invalidDatePlaceholder;
  }

  try {
    return UTCDateFormatter.format(date);
  } catch (e) {
    logError(e);
    return invalidDatePlaceholder;
  }
};

const PluralRules = new Intl.PluralRules('default');

export const messagingInDays = (days: number) => {
  const suffixes = {
    zero: 'days',
    one: 'day',
    two: 'days',
    few: 'days',
    many: 'days',
    other: 'days',
  } satisfies Record<Intl.LDMLPluralRule, string>;

  const suffix = suffixes[PluralRules.select(days)];

  return `${days} ${suffix}`;
};

export const formatProductOptions = <Option extends { value: string }>(
  options: Option[],
) =>
  options.length ?
    options
      .map(({ value }) => value)
      .filter((t) => t !== 'Default Title')
      .join(' • ')
  : '';

export const formatDisplayValue =
  <T>(formatter: (value: T) => string) =>
  (value: T) => ({
    value,
    display: formatter(value),
  });
